import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { useForm, Controller } from "react-hook-form";
import CustomerMastersList from './CustomerMastersList'
const CustomerMasters = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const onSubmit = (data, e) => { }
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    return (
        <div className="planning">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Customer Master</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Create Customer Master">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Customer ID</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Customer ID"
                                                name="customerID"
                                                {...register("customerID", {
                                                    required: "Please enter Customer ID",

                                                })}
                                            />
                                            {errors.customerID && <span className="err-msg">{errors.customerID.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Customer Name</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Customer Name"
                                                name="customerName"
                                                {...register("customerName", {
                                                    required: "Please enter Customer Name",

                                                })}
                                            />
                                            {errors.customerName && <span className="err-msg">{errors.customerName.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>No Of Plants</label>
                                            <input type="number" className="form-control form-control-sm" placeholder="Enter No Of Plants"
                                                name="noOfSites"
                                                {...register("noOfSites", {
                                                    required: "Please enter No Of Plants",
                                                })}
                                            />
                                            {errors.noOfSites && <span className="err-msg">{errors.noOfSites.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Admin Mail ID</label>
                                            <input type="email" className="form-control form-control-sm" placeholder="Enter Admin Mail ID"
                                                name="adminMailID"
                                                {...register("adminMailID", {
                                                    required: "Please enter Admin Mail ID",
                                                })}
                                            />
                                            {errors.adminMailID && <span className="err-msg">{errors.adminMailID.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Admin Contact</label>
                                            <input type="number" className="form-control form-control-sm" placeholder="Enter Admin Contact"
                                                name="adminContact"
                                                {...register("adminContact", {
                                                    required: "Please enter Admin Contact",
                                                })}
                                            />
                                            {errors.adminContact && <span className="err-msg">{errors.adminContact.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Address</label>
                                            <textarea className="form-control form-control-sm" placeholder="Enter Address"
                                                name="address"
                                                {...register("address", {
                                                    required: "Please enter Address",

                                                })}
                                            />
                                            {errors.address && <span className="err-msg">{errors.address.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                                        <input type="reset" value="Reset" class="btn btn-secondary newBtn" />
                                    </div>
                                </div>
                            </form>
                        </Tab>
                        <Tab label="Customers List">
                            <CustomerMastersList></CustomerMastersList>
                        </Tab>

                    </Tabs>
                </div>
            </div>
        </div>
    );
}

const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}

export default CustomerMasters;