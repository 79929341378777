import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import DataTable from 'react-data-table-component';
import { FiAlertTriangle, } from "react-icons/fi";
import SkillView from '../planning/skillSetView';
import MaterialInfo from '../planning/materialInfo';
import Datepicker from 'react-datepicker';
import { AiOutlineCalendar, AiOutlineAlignCenter } from "react-icons/ai";
import { userInfo } from '../pages/const';
import { useForm, Controller } from "react-hook-form";
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { planningList, getPlannedTaskDataForWorkId, purchaseOrderInProduction } from "../pages/const";
import moment from 'moment';

const Planning = (props) => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const [siteList, setSiteList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const customCellStyles = [
        {
            columns: ['taskId'],
            conditionalCellStyles: [
                {
                    when: row => row.riskDetails['risk'] === true,
                    style: {
                        color: 'red',
                    },
                },
            ],
        },
    ];
    const taskcolumns = [
        {
            name: "Batch ID",
            selector: "taskId",
            sortable: true,
            minWidth: '200px',
            cell: (row) => {
                // console.log("row.riskDetails", row.riskDetails)
                if (row.riskDetails !== undefined) {
                    return <span style={{ color: row.riskDetails['risk'] === true ? 'red' : 'black' }}> {row.taskId}</span>
                }
                else {
                    return <span> {row.taskId}</span>
                }
            }
        },
        {
            name: "Description",
            // selector: "taskDescription",
            sortable: true,
            minWidth: '250px',
            selector: row => (
                <span title={row.taskDescription}>
                    <span>{row.taskDescription}</span>
                </span>
            )
        },
        {
            name: "Predecessors",
            selector: "taskPredecessors",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Successors",
            selector: "taskSuccessors",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Start Date",
            selector: row => moment(row.taskStartDate).format('DD-MMM-YYYY'),
            sortable: true,
            minWidth: '250px'
        },
        {
            name: "End Date",
            selector: row => moment(row.taskEndDate).format('DD-MMM-YYYY'),
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Purchase Order",
            selector: row => row.orderNumber,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Supplier Name",
            selector: row => row.supplierName,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: "Skill Set",
            sortable: true,
            minWidth: '120px',
            cell: (row) =>
                <i className="fas fa-eye view-btn" onClick={() => taskClickHandler(row)} id={row.taskID}></i>
        },
        {
            name: "Material Info",
            selector: "",
            sortable: true,
            minWidth: '150px',
            cell: (row) =>
                <i className="fas fa-eye view-btn" onClick={() => materialClickHandler(row)} id={row.taskID}></i>
        },

    ];
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    });
    const [showLoader, setShowLoader] = useState(false);
    const [showSkillViewPopup, setShowSkillViewPopup] = useState(false);
    const [showMaterialInfoPopup, setShowMaterialInfoPopup] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [items, setItems] = useState([]);
    const [itemsOnTrack, setItemsOnTrack] = useState([]);
    const [details, setDetails] = useState();
    const [showDetails, setShowDetails] = useState(false)
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [key, setKey] = useState(0);
    const [skillSetData, setSkillSetData] = useState([]);
    const [materialDataSet, setMaterialDataSet] = useState([]);
    const [selectedTaskID, setSelectedTaskID] = useState('')
    const taskClickHandler = (data) => {
        setSelectedTaskID(data.taskId)
        setSkillSetData(data.skillSet)
        setShowSkillViewPopup(true);
    }
    const materialClickHandler = (data) => {
        setSelectedTaskID(data.taskId)
        setMaterialDataSet(data.materialData)
        setShowMaterialInfoPopup(true)
    }


    useEffect(() => {
        setShowLoader(true);
        tabList();
        filtersData({ type: 'customer' })
    }, []);
    const filtersData = (type) => {
        setShowLoader(true)
        userInfo(type).then(res => {
            setShowLoader(false)
            if (res.status === 200) {
                setCustomerList(res.data.data.customerIds)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const fromdateSelector = (date) => {
        setFromDate(date);
    }
    const todateSelector = (date) => {
        setToDate(date);
    }
    const tabList = () => {
        // console.log(e, fromDate, toDate, "ee data")
        let submitPayload = {
            fromDate: moment(fromDate).add(1, 'day').toISOString(),
            toDate: moment(toDate).add(1, 'day').toISOString(),
            siteId: getValues('site'),
            customerId: getValues('customer')

        }
        planningList(submitPayload).then(res => {
            setShowLoader(false)
            if (res.status === 200) {
                setItems(res.data.data['At Risk']);
                setItemsOnTrack(res.data.data['On Track'])
                setDetails()

                if (res.data.data['At Risk'][0] != undefined) {
                    planningWorkDetails(res.data.data['At Risk'][0].workId);
                }

            }
        }).catch((err) => {
            console.log(err)
        });
    }


    const handleItemClick = (index, workID) => {
        setActiveIndex(index);
        planningWorkDetails(workID)
    };
    const onHandleChangeSite = (e) => {

    }
    const onHandleChangeCustomer = (e) => {
        let customerInfo = getValues('customer')
        if (customerInfo) {
            userInfo({ type: 'site', customerId: customerInfo }).then(res => {
                setShowLoader(false)
                if (res.status === 200) {
                    setSiteList(res.data.data.siteIds)
                }
            }).catch((err) => {
                console.log(err)
            });
        }

    }
    useEffect(() => {
        // setShowLoader(true)
        userInfo().then(res => {
            if (res.status === 200) {
                setSiteList(res.data.data.siteIds)
                setCustomerList(res.data.data.customerIds)
                // setShowLoader(false)
            }
        })
    }, [])


    const planningWorkDetails = (workId) => {
        setDetails()
        getPlannedTaskDataForWorkId({ workId: workId }).then(res => {
            if (res.status === 200) {
                setDetails(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        });

    }
    const onSubmit = (e) => {

        tabList()

    }
    const handleTabChange = (selectedKey) => {
        setKey(selectedKey);
        if (selectedKey == 0) {
            planningWorkDetails(items[0].workId);
        } else {
            if (itemsOnTrack.length != 0) {
                planningWorkDetails(itemsOnTrack[0].workId);
            } else {
                setDetails()
            }

        }
        // Additional logic you want to perform when the tab changes
    };
    return (
        <div className="planning">
            <div className='card'>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-12 mb-0" style={{}}>
                                <div className="row">
                                    <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                                </div>
                                <div className="row mb-0">
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="farmer"
                                                {...register("customer", {
                                                    onChange: (e) => { onHandleChangeCustomer(e) }
                                                })}

                                            >
                                                <option value="">-Select Customer-</option>
                                                {customerList.map((item) => (
                                                    <option value={item}>{item}</option>
                                                ))}

                                            </select>

                                        </div>
                                    </div>
                                    <div className='col-md-2 mb-0'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="uom"
                                                {...register("site", {
                                                    onChange: (e) => { onHandleChangeSite(e) }
                                                })}

                                            >
                                                <option value="">-Select Production Plant-</option>
                                                {siteList.map((item) => (
                                                    <option value={item}>{item}</option>
                                                ))}

                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-md-2 mb-0">

                                        <Datepicker
                                            selected={fromDate}
                                            onChange={fromdateSelector}
                                            //showTimeSelect
                                            //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                            placeholderText="Select From Date"

                                            //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                            dateFormat="dd-MMM-yyyy"
                                            name="fromDate"
                                            className="form-control form-control-sm"

                                        >
                                        </Datepicker>
                                    </div>
                                    <div className="col-md-2 mb-0">
                                        <Datepicker
                                            selected={toDate}
                                            onChange={todateSelector}
                                            placeholderText="Select To Date"
                                            dateFormat="dd-MMM-yyyy"
                                            name="closeDate"
                                            className="form-control form-control-sm"
                                        >
                                        </Datepicker>
                                    </div>
                                    <div className="col mb-0">
                                        <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                    </div>
                                    <div className="col mb-0">
                                        <ul className='extraLinks'>
                                            <li><AiOutlineAlignCenter ></AiOutlineAlignCenter > Gantt View</li>
                                            <li><AiOutlineCalendar></AiOutlineCalendar> Calendar View</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

            </div>
            <div className="card">
                <div class="card-body">

                    <div className='row'>

                        <div className='col-md-2 sideBorder'>
                            {/* <h2 class="m-0 text-dark" style={{fontSize: 14}}>Production IDs</h2> */}
                            <Tabs activeKey={key} onSelect={handleTabChange}>
                                <Tab label="At Risk" eventKey="atRisk" title="At Risk">
                                    <div class="input-group">
                                        <input type="search" className="form-control form-control-sm"
                                            placeholder="Search Production ID"
                                        />
                                        <div class="input-group-append">
                                            <button type="submit" className="btn btn-sm btn-default">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <ul className='sideNav'>
                                        {items.length !== 0 && items.map((item, index) => (
                                            <li
                                                key={item.workId}
                                                className={index === activeIndex ? 'workItemActive' : ''}
                                                onClick={() => handleItemClick(index, item.workId)}
                                            >
                                                {item.workId}
                                                {/* <span className={`circle ${item.color}Indicator`}></span> */}
                                            </li>
                                        ))}
                                    </ul>
                                </Tab>
                                <Tab label="On Track" eventKey="onTrack" title="On Track">
                                    <div class="input-group">
                                        <input type="search" className="form-control form-control-sm"
                                            placeholder="Search Production ID"
                                        />
                                        <div class="input-group-append">
                                            <button type="submit" className="btn btn-sm btn-default">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <ul className='sideNav'>
                                        {itemsOnTrack.length !== 0 && itemsOnTrack.map((item, index) => (
                                            <li
                                                key={item.workId}
                                                className={index === activeIndex ? 'workItemActive' : ''}
                                                onClick={() => handleItemClick(index, item.workId)}
                                            >
                                                {item.workId}
                                                {/* <span className={`circle ${item.color}Indicator`}></span> */}
                                            </li>
                                        ))}
                                    </ul>
                                </Tab>
                            </Tabs>
                        </div>

                        {details ?
                            <div className='col-md-10'>
                                {/* <div class="row mb-2">
                                    <div class="col-sm-6">
                                        <h2 class="m-0 text-dark" style={{fontSize: 14}}>Production Infomation</h2>
                                    </div>
                                </div> */}
                                <div className='workHeader'>

                                    <div className='row' style={{justifyContent:'space-between'}}>

                                        <div >
                                           <strong>Production ID:</strong> {details.workId}
                                        </div>
                                        <div >
                                            <strong>Production Plant ID: </strong>  {details.siteId}
                                        
                                        </div>
                                        <div>
                                        <strong>Contractor: </strong> {details.contractor}
                                        </div>
                                        <div>
                                            <div className='status'>
                                                <strong>Status: </strong> <span style={{ backgroundColor: key == 0 ? 'red' : 'green' }}>{key == 0 ? "At Risk" : "On Track"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='workSummary row'>

                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='summaryHeader'>Production Type:</div>
                                            </div>
                                            <div className='col-md-12'>
                                                <span>{details.workType}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='summaryHeader'>Description:</div>
                                            </div>
                                            <div className='col-md-12'>
                                                <span>{details.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='summaryHeader'>Production Schedule Start Date:</div>
                                            </div>
                                            <div className='col-md-12'>
                                                <span>{moment(details.startDate).format('DD-MMM-YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='summaryHeader'>Production Schedule End Date:</div>
                                            </div>
                                            <div className='col-md-12'>
                                                <span>{moment(details.endDate).format('DD-MMM-YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='taskView'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <h2>Batches</h2>
                                            <DataTable
                                                columns={taskcolumns}
                                                data={details.taskData}
                                                defaultSortField="taskID"
                                                pagination
                                                dense
                                                selectableRowsHighlight='true'
                                                compact
                                                highlightOnHover='true'
                                                striped
                                                conditionalCellStyles={customCellStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <SkillView key={Math.random()}
                                    showPopup={showSkillViewPopup}
                                    taskID={selectedTaskID}
                                    skillData={skillSetData}
                                    closePopup={() => { setShowSkillViewPopup(false) }}
                                ></SkillView>
                                <MaterialInfo key={Math.random()}
                                    showPopup={showMaterialInfoPopup}
                                    taskID={selectedTaskID}
                                    materialData={materialDataSet}
                                    closePopup={() => { setShowMaterialInfoPopup(false) }}
                                ></MaterialInfo>
                            </div> :
                            <div className='col-md-10' >
                                <div style={{ margin: '50px', justifyContent: 'center', display: 'flex' }}>
                                    <div className='row'>
                                        <strong>No Data Found</strong>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {showLoader ?
                <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                    {indicatorEl} {/* renders only while loading */}
                </section> : ""
            }
        </div>
    );
}

export default Planning;