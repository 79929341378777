import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env, getMaterialName, supplierInventoryList } from './const';
// import { headers } from '../utils/common';
// import { Role } from '../utils/role';
// import { getRole } from '../utils/common';
import DataTable from "react-data-table-component"
import { getMaterialCategory } from '../pages/const';
import Loader from './Loader';
const SupplierInventory = () => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const [materialCategoryList, setMaterialCategoryList] = useState([])
    const [materialNameList, setMaterialNameList] = useState([])
    const [showLoader, setShowLoader] = useState(false);
    const [rowData, setRowData] = useState()
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    let columns = [];
    partyType === "Supplier" ? (
        columns = [
            {
                name: "Material ID",
                selector: "materialId",
                sortable: true,
                minWidth: '200px'
            },
            {
                name: "Material Category",
                selector: "materialCategory",
                sortable: true,
                minWidth: '350px'
            },
            {
                name: "Material Name",
                selector: "materialName",
                sortable: true,
                minWidth: '350px'
            },
            {
                name: "Material Description",
                selector: "materialDescription",
                sortable: true,
                minWidth: '350px'
            },
            {
                name: "Available Stock",
                selector: "availableStock",
                sortable: true,
                minWidth: '200px'
            },
            {
                name: "UOM",
                selector: "uom",
                sortable: true,
            },
            {
                name: "Date",
                selector: "date",
                sortable: true,
                minWidth: '200px'
            },
            {
                name: "Time",
                selector: row => row.time.split("T")[0],
                sortable: true,
                minWidth: '200px'
            }
        ]
    ) : (
        columns = [
            {
                name: "Supplier",
                selector: "supplierName",
                sortable: true,
                maxWidth: '250px'
            },
            {
                name: "Material ID",
                selector: "materialId",
                sortable: true,
                minWidth: '150px'
            },
            {
                name: "Material Category",
                selector: "materialCategory",
                sortable: true,
                minWidth: '350px'
            },
            {
                name: "Material Name",
                selector: "materialName",
                sortable: true,
                minWidth: '350px'
            },
            {
                name: "Material Description",
                selector: "materialDescription",
                sortable: true,
                minWidth: '350px'
            },
            {
                name: "Available Stock",
                selector: "availableStock",
                sortable: true,
                minWidth: '200px'
            },
            {
                name: "UOM",
                selector: "uom",
                sortable: true,
                minWidth: '50px'
            },
            {
                name: "Date",
                selector: "date",
                sortable: true,
                minWidth: '200px'
            },
            {
                name: "Time",
                selector: row => row.time.split("T")[0],
                sortable: true,
                minWidth: '200px'
            }
        ]
    );

    const onHandleChangeMaterilCategory = (e) => {
        console.log(e.target.value, "event")
        setMaterialNameList([])
        setShowLoader(true)
        getMaterialName({ materialCategory: e.target.value }).then(res => {
            setShowLoader(false)
            console.log("showLoader", res)
            if (res.status === 200) {
                console.log("data111", res.data)
                setMaterialNameList(res.data.data)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const onHandleChangeMaterialName = (e) => {
        let value = getValues('supplier')
        console.log(value, "selectedValue")
        let inventoryRequest = {
            materialCategory: value,
            materialName: e.target.value
        }
        supplierInventory(inventoryRequest)
    }
    const supplierInventory = (data) => {
        setShowLoader(true)
        supplierInventoryList(data).then(res => {
            setShowLoader(false)
            console.log("showLoader", res)
            if (res.status === 200) {
                console.log("data111", res.data)
                setRowData(res.data.data)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    useEffect(() => {
        setShowLoader(true)
        getMaterialCategory().then(res => {
            setShowLoader(false)
            console.log("showLoader", res)
            if (res.status === 200) {
                console.log("data111", res.data)
                setMaterialCategoryList(res.data.data)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
        supplierInventory({})
    }, [])

    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Supplier Inventory</h2>
                </div>
            </div>

            <div className="card">
                <div class="card-body">
                    <div>
                        <div className="row">
                            <div className="col-md-4">
                                <form>
                                    <div class="input-group">

                                        {/* <label htmlFor>Material Category</label> */}
                                        <select class="form-control form-control-sm" name="farmer"
                                            {...register("supplier", {
                                                required: "Please Material Category",
                                                onChange: (e) => { onHandleChangeMaterilCategory(e) }
                                            })}
                                        >
                                            <option value="">-Select Material Category-</option>
                                            {materialCategoryList.map((item) => (
                                                <option value={item}>{item}</option>
                                            ))}

                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4">
                                <form>
                                    <div class="input-group">

                                        {/* <label htmlFor>Material Name</label> */}
                                        <select class="form-control form-control-sm" name="farmer"
                                            {...register("supplierName", {
                                                required: "Please select Material Name",
                                                onChange: (e) => { onHandleChangeMaterialName(e) }
                                            })}
                                        >
                                            <option value="">-Select Material Name-</option>
                                            {materialNameList.map((item) => (
                                                <option value={item}>{item}</option>
                                            ))}

                                        </select>
                                    </div>
                                </form>
                            </div>
                            {showLoader ? <Loader /> : ''}
                        </div>
                        <br></br>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={rowData}
                                defaultSortField="batchID"
                                pagination
                                //selectableRows
                                // selectableRowsComponent={BootyCheckbox}
                                // onSelectedRowsChange = {handleChange}
                                dense
                                selectableRowsHighlight='true'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SupplierInventory;