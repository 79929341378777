import DataTable from "react-data-table-component";
import Datepicker from 'react-datepicker';
import React, { useState } from 'react';
const DispatchInfo = () => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const columns = [
        {
            name: 'ORDER NUMBER',
            selector: "orderNumber",
            sortable: true
        },
        {
            name: 'INVOICE NUMBER',
            selector: "invoiceNo",
            sortable: true
        },
        {
            name: 'INVOICE DATE',
            selector: "invoiceDate",
            sortable: true
        },
        {
            name: 'PICK UP LOCATION',
            selector: "pickUpLocation",
            sortable: true
        },
        {
            name: 'PICK UP DATE',
            selector: "pickUpDate",
            sortable: true
        },
        {
            name: 'CONSIGNEE ID',
            selector: "consigneeID",
            sortable: true
        },
        {
            name: 'CONSIGNEE NAME',
            selector: "consigneeName",
            sortable: true
        },
        {
            name: 'DROP OFF LOCATION',
            selector: "dropOffLocation",
            sortable: true
        },
        {
            name: 'INCO TERMS',
            selector: "incoTerms",
            sortable: true
        },
        {
            name: 'COMMODITY TYPE',
            selector: "commodityType",
            sortable: true
        },
        {
            name: 'MATERIAL NAME',
            selector: "materialName",
            sortable: true
        },
        {
            name: 'MATERIAL DESCRIPTION',
            selector: "materialDescription",
            sortable: true
        },
        {
            name: 'QTY',
            selector: "qty",
            sortable: true
        },
        {
            name: 'HS CODE',
            selector: "HSCode",
            sortable: true
        },
        {
            name: 'HS CODE DESCRIPTION',
            selector: "HSCodeDescription",
            sortable: true
        },
        {
            name: 'NO OF PACKAGES',
            selector: "noofPackages",
            sortable: true
        },
        {
            name: 'PACKAGE TYPE',
            selector: "packageType",
            sortable: true
        },
        {
            name: 'PACKAGE WEIGHT',
            selector: "packageWeight",
            sortable: true
        },
        {
            name: 'LENGHT',
            selector: "length",
            sortable: true
        },
        {
            name: 'WIDTH',
            selector: "width",
            sortable: true
        },
        {
            name: 'HEIGHT',
            selector: "height",
            sortable: true
        },
        {
            name: 'SPECIAL INSTRUCTION',
            selector: "specialInstructions",
            sortable: true
        },
        {
            name: 'HZ GOODS',
            selector: "HZGoods",
            sortable: true
        },
        {
            name: 'MODE OF SHIPMENTS',
            selector: "modeOfShipment",
            sortable: true
        },
        {
            name: 'CARRIER ID',
            selector: "carrierID",
            sortable: true
        },
        {
            name: 'CARRIER NAME',
            selector: "carrierName",
            sortable: true
        },
        {
            name: 'BOOKING REF NO',
            selector: "bookingRefNo",
            sortable: true
        },
        {
            name: 'HBL NO',
            selector: "hblNo",
            sortable: true
        },
        {
            name: 'MBL NO',
            selector: "mblNo",
            sortable: true
        },
        {
            name: 'CONTAINER NO',
            selector: "containerNo",
            sortable: true
        },
        {
            name: 'AWB NO',
            selector: "awbNo",
            sortable: true
        },
        {
            name: 'REQUESTED DATE OF DELIVERY',
            selector: "requestedDateOfDelivery",
            sortable: true
        },
    ];

    const data = [
        // {
        //     orderNumber: "ORD001",
        //     invoiceNo: "",
        //     invoiceDate: "",
        //     pickUpLocation: "",
        //     pickUpDate: "",
        //     consigneeID: "",
        //     consigneeName: "",
        //     dropOffLocation: "",
        //     incoTerms: "",
        //     commodityType: "",
        //     materialName: "",
        //     materialDescription: "",
        //     qty: "",
        //     HSCode: "",
        //     HSCodeDescription: "",
        //     noofPackages: "",
        //     packageType: "",
        //     packageWeight: "",
        //     length: "",
        //     width: "",
        //     height: "",
        //     specialInstructions: "",
        //     HZGoods: "",
        //     modeOfShipment: "",
        //     carrierID: "",
        //     carrierName: "",
        //     bookingRefNo: "",
        //     hblNo: "",
        //     mblNo: "",
        //     containerNo: "",
        //     awbNo: "",
        //     requestedDateOfDelivery: "",
        // }

    ]

    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Production Schedule</h2>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-md-12 mb-0" style={{}}>
                            <div className="row">
                                <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                            </div>
                            <div className="row mb-0">
                                {(partyType === "Linesight" || partyType === "Supplier") &&
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="farmer">
                                                <option value="">-Select Customer-</option>
                                            </select>

                                        </div>
                                    </div>
                                }
                                {(partyType === "Customer" || partyType === "Contractor" || partyType === "Linesight") &&
                                    <div className='col-md-2 mb-0'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="uom">
                                                <option value="">-Select Production Plant-</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className="col-md-2 mb-0">
                                    <Datepicker
                                        placeholderText="Select From Date"
                                        maxDate={new Date()}
                                        //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                        dateFormat="dd-MMM-yyyy"
                                        name="closeDate"
                                        className="form-control form-control-sm"
                                    >
                                    </Datepicker>
                                </div>
                                <div className="col-md-2 mb-0">
                                    <Datepicker
                                        placeholderText="Select To Date"
                                        maxDate={new Date()}
                                        //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                        dateFormat="dd-MMM-yyyy"
                                        name="closeDate"
                                        className="form-control form-control-sm"
                                    >
                                    </Datepicker>
                                </div>
                                <div className="col mb-0">
                                    <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={data}
                                defaultSortField="batchID"
                                pagination
                                //selectableRows
                                // selectableRowsComponent={BootyCheckbox}
                                // onSelectedRowsChange = {handleChange}
                                dense
                                selectableRowsHighlight='true'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DispatchInfo;
