import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env } from './const';
// import { headers } from '../utils/common';
// import { Role } from '../utils/role';
// import { getRole } from '../utils/common';
import DataTable from "react-data-table-component"
const Inventory = (props) => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const columns = [
        {
            name: "Production Plant ID",
            selector: "siteID ",
            sortable: true,
            // maxWidth: '60px'
        },
        {
            name: "Mat ID",
            selector: "productID",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "Material Name",
            selector: "materialName",
            sortable: true,
            minWidth: '350px'
        },
        {
            name: "Opening QTY",
            selector: "openingstock",
            sortable: true,
            minWidth: '200px',
            cell: row => (
                row.openingstock == 0 ?
                    <span className='textHiglighter' style={{ background: 'red' }}>{row.openingstock}</span> :
                    <span className='textHiglighter'>{row.openingstock}</span>
            )
        },
        {
            name: "Inward",
            selector: "inward",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "Inward Returns",
            selector: "inwardreturns",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Outward",
            selector: "outward",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Outward Returns",
            selector: "outwardreturns",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Expired QTY",
            selector: '',
            sortable: true,
            minWidth: '150px',
            cell: row => (
                row.openingstock > 10 ? String(row.openingstock)[0] : 0
            )
        },
        {
            name: "Closing QTY",
            selector: "ClosingStock",
            sortable: true,
            minWidth: '200px',
            cell: row => (
                row.ClosingStock == 0 ?
                    <span className='textHiglighter' style={{ background: 'red' }}>{row.ClosingStock}</span> :
                    <span className='textHiglighter'>{row.ClosingStock}</span>
            )
        }
    ];
    const [data, setData] = useState([
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL008",
            "materialName": "Metal Horizontal Louver Blinds",
            "openingstock": 52,
            "inward": 5,
            "inwardreturns": 0,
            "outward": 46,
            "outwardreturns": 9,
            "Damage": 6,
            "ClosingStock": 20
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL011",
            "materialName": "Roller Window Shades",
            "openingstock": 34,
            "inward": 8,
            "inwardreturns": 0,
            "outward": 28,
            "outwardreturns": 12,
            "Damage": 9,
            "ClosingStock": 26
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL013",
            "materialName": "Cellular Shades",
            "openingstock": 74,
            "inward": 7,
            "inwardreturns": 0,
            "outward": 68,
            "outwardreturns": 11,
            "Damage": 8,
            "ClosingStock": 24
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL016",
            "materialName": "Dormitory Casework",
            "openingstock": 51,
            "inward": 4,
            "inwardreturns": 0,
            "outward": 45,
            "outwardreturns": 8,
            "Damage": 5,
            "ClosingStock": 18
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL020",
            "materialName": "Wood Countertops",
            "openingstock": 27,
            "inward": 6,
            "inwardreturns": 0,
            "outward": 21,
            "outwardreturns": 10,
            "Damage": 7,
            "ClosingStock": 22
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL023",
            "materialName": "Parking Meters",
            "openingstock": 36,
            "inward": 9,
            "inwardreturns": 0,
            "outward": 30,
            "outwardreturns": 13,
            "Damage": 10,
            "ClosingStock": 28
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL025",
            "materialName": "Parking Gates",
            "openingstock": 46,
            "inward": 4,
            "inwardreturns": 0,
            "outward": 40,
            "outwardreturns": 8,
            "Damage": 5,
            "ClosingStock": 18
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL030",
            "materialName": "Pedestrian Security Equipment",
            "openingstock": 53,
            "inward": 6,
            "inwardreturns": 0,
            "outward": 47,
            "outwardreturns": 10,
            "Damage": 7,
            "ClosingStock": 22
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL031",
            "materialName": "Electronic Detection and Counting Systems",
            "openingstock": 57,
            "inward": 7,
            "inwardreturns": 0,
            "outward": 51,
            "outwardreturns": 11,
            "Damage": 8,
            "ClosingStock": 24
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL037",
            "materialName": "Computers",
            "openingstock": 64,
            "inward": 2,
            "inwardreturns": 0,
            "outward": 58,
            "outwardreturns": 6,
            "Damage": 3,
            "ClosingStock": 14
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL038",
            "materialName": "Stainless Steel Reinforcement Bars",
            "openingstock": 52,
            "inward": 5,
            "inwardreturns": 0,
            "outward": 46,
            "outwardreturns": 9,
            "Damage": 6,
            "ClosingStock": 20
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL044",
            "materialName": "Stamped Concrete Finishing",
            "openingstock": 34,
            "inward": 8,
            "inwardreturns": 0,
            "outward": 28,
            "outwardreturns": 12,
            "Damage": 9,
            "ClosingStock": 26
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL050",
            "materialName": "Medium-Voltage Circuit Breaker Switchgear",
            "openingstock": 74,
            "inward": 7,
            "inwardreturns": 0,
            "outward": 68,
            "outwardreturns": 11,
            "Damage": 8,
            "ClosingStock": 24
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL057",
            "materialName": "Medium-Voltage Surge Arresters",
            "openingstock": 51,
            "inward": 4,
            "inwardreturns": 0,
            "outward": 45,
            "outwardreturns": 8,
            "Damage": 5,
            "ClosingStock": 18
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL058",
            "materialName": "Low-Voltage Distribution Transformers",
            "openingstock": 27,
            "inward": 6,
            "inwardreturns": 0,
            "outward": 21,
            "outwardreturns": 10,
            "Damage": 7,
            "ClosingStock": 22
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL059",
            "materialName": "Low-Voltage Transformers for Load Centers",
            "openingstock": 36,
            "inward": 9,
            "inwardreturns": 0,
            "outward": 30,
            "outwardreturns": 13,
            "Damage": 10,
            "ClosingStock": 28
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL064",
            "materialName": "Reduced-Voltage Motor Controllers",
            "openingstock": 46,
            "inward": 4,
            "inwardreturns": 0,
            "outward": 40,
            "outwardreturns": 8,
            "Damage": 5,
            "ClosingStock": 18
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL065",
            "materialName": "Variable-Frequency Motor Controllers",
            "openingstock": 53,
            "inward": 6,
            "inwardreturns": 0,
            "outward": 47,
            "outwardreturns": 10,
            "Damage": 7,
            "ClosingStock": 22
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL066",
            "materialName": "Engine Generators",
            "openingstock": 57,
            "inward": 7,
            "inwardreturns": 0,
            "outward": 51,
            "outwardreturns": 11,
            "Damage": 8,
            "ClosingStock": 24
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL071",
            "materialName": "Batteries",
            "openingstock": 64,
            "inward": 2,
            "inwardreturns": 0,
            "outward": 58,
            "outwardreturns": 6,
            "Damage": 3,
            "ClosingStock": 14
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL072",
            "materialName": "Central Battery Equipment",
            "openingstock": 52,
            "inward": 5,
            "inwardreturns": 0,
            "outward": 46,
            "outwardreturns": 9,
            "Damage": 6,
            "ClosingStock": 20
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL077",
            "materialName": "LED Interior Lighting",
            "openingstock": 34,
            "inward": 8,
            "inwardreturns": 0,
            "outward": 28,
            "outwardreturns": 12,
            "Damage": 9,
            "ClosingStock": 26
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL087",
            "materialName": "Security Lighting",
            "openingstock": 74,
            "inward": 7,
            "inwardreturns": 0,
            "outward": 68,
            "outwardreturns": 11,
            "Damage": 8,
            "ClosingStock": 24
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL095",
            "materialName": "Electronic Markerboards",
            "openingstock": 51,
            "inward": 4,
            "inwardreturns": 0,
            "outward": 45,
            "outwardreturns": 8,
            "Damage": 5,
            "ClosingStock": 18
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL104",
            "materialName": "Toilet Compartments",
            "openingstock": 27,
            "inward": 6,
            "inwardreturns": 0,
            "outward": 21,
            "outwardreturns": 10,
            "Damage": 7,
            "ClosingStock": 22
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL109",
            "materialName": "Folding Gates",
            "openingstock": 36,
            "inward": 9,
            "inwardreturns": 0,
            "outward": 30,
            "outwardreturns": 13,
            "Damage": 10,
            "ClosingStock": 28
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL166",
            "materialName": "Security Sinks",
            "openingstock": 46,
            "inward": 4,
            "inwardreturns": 0,
            "outward": 40,
            "outwardreturns": 8,
            "Damage": 5,
            "ClosingStock": 18
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL167",
            "materialName": "Access Control Software",
            "openingstock": 53,
            "inward": 6,
            "inwardreturns": 0,
            "outward": 47,
            "outwardreturns": 10,
            "Damage": 7,
            "ClosingStock": 22
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL168",
            "materialName": "Access Control Operating Systems",
            "openingstock": 57,
            "inward": 7,
            "inwardreturns": 0,
            "outward": 51,
            "outwardreturns": 11,
            "Damage": 8,
            "ClosingStock": 24
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL172",
            "materialName": "Access Control Cloud Services",
            "openingstock": 64,
            "inward": 2,
            "inwardreturns": 0,
            "outward": 58,
            "outwardreturns": 6,
            "Damage": 3,
            "ClosingStock": 14
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL173",
            "materialName": "Security Access Detection",
            "openingstock": 52,
            "inward": 5,
            "inwardreturns": 0,
            "outward": 46,
            "outwardreturns": 9,
            "Damage": 6,
            "ClosingStock": 20
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL178",
            "materialName": "Keypads",
            "openingstock": 34,
            "inward": 8,
            "inwardreturns": 0,
            "outward": 28,
            "outwardreturns": 12,
            "Damage": 9,
            "ClosingStock": 26
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL179",
            "materialName": "Biometric Identity Devices",
            "openingstock": 74,
            "inward": 7,
            "inwardreturns": 0,
            "outward": 68,
            "outwardreturns": 11,
            "Damage": 8,
            "ClosingStock": 24
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL180",
            "materialName": "Access Control Credentials",
            "openingstock": 51,
            "inward": 4,
            "inwardreturns": 0,
            "outward": 45,
            "outwardreturns": 8,
            "Damage": 5,
            "ClosingStock": 18
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL181",
            "materialName": "Access Control Cards",
            "openingstock": 27,
            "inward": 6,
            "inwardreturns": 0,
            "outward": 21,
            "outwardreturns": 10,
            "Damage": 7,
            "ClosingStock": 22
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL182",
            "materialName": "Wireless Key Fobs",
            "openingstock": 36,
            "inward": 9,
            "inwardreturns": 0,
            "outward": 30,
            "outwardreturns": 13,
            "Damage": 10,
            "ClosingStock": 28
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL183",
            "materialName": "Analog Camera",
            "openingstock": 46,
            "inward": 4,
            "inwardreturns": 0,
            "outward": 40,
            "outwardreturns": 8,
            "Damage": 5,
            "ClosingStock": 18
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL184",
            "materialName": "IP Cameras",
            "openingstock": 53,
            "inward": 6,
            "inwardreturns": 0,
            "outward": 47,
            "outwardreturns": 10,
            "Damage": 7,
            "ClosingStock": 22
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL185",
            "materialName": "Panoramic IP Cameras",
            "openingstock": 57,
            "inward": 7,
            "inwardreturns": 0,
            "outward": 51,
            "outwardreturns": 11,
            "Damage": 8,
            "ClosingStock": 24
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL186",
            "materialName": "Analytic Packages for IP Cameras",
            "openingstock": 64,
            "inward": 2,
            "inwardreturns": 0,
            "outward": 58,
            "outwardreturns": 6,
            "Damage": 3,
            "ClosingStock": 14
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL187",
            "materialName": "Specialty Cameras",
            "openingstock": 52,
            "inward": 5,
            "inwardreturns": 0,
            "outward": 46,
            "outwardreturns": 9,
            "Damage": 6,
            "ClosingStock": 20
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL192",
            "materialName": "Smoke Detection Sensors",
            "openingstock": 34,
            "inward": 8,
            "inwardreturns": 0,
            "outward": 28,
            "outwardreturns": 12,
            "Damage": 9,
            "ClosingStock": 26
        },
        {
            "siteName": "New York",
            "siteID ": "SITE001",
            "productID": "MTRL193",
            "materialName": "Radiation Detection",
            "openingstock": 74,
            "inward": 7,
            "inwardreturns": 0,
            "outward": 68,
            "outwardreturns": 11,
            "Damage": 8,
            "ClosingStock": 24
        }
    ]);
    const [siteData, setSiteData] = useState([]);

    const sitesList = () => {
        axios.get(env.produrl + '/misc/siteMaster')
            .then(res => {
                const data = res.data.list;
                setSiteData(data);
                if (data.length > 0) {
                    invList(data[0].siteID);
                }
            }).catch((err) => {
                console.log(err)
            });
    }

    const invList = (_siteID) => {
        const date = moment().format('YYYY-MM-DD')
        axios.get(`${env.produrl}/inventory/data/${date}/${_siteID}`)
            .then(res => {
                setData(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
    }
    const siteChange = (e) => {
        invList(e.target.value);
    }
    useEffect(() => {
        // sitesList();
    }, [])

    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Production Plant Inventory</h2>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-md-12 mb-0" style={{}}>
                            <div className="row">
                                <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                            </div>
                            <div className="row mb-0">
                                {(partyType === "Linesight" || partyType === "Supplier") &&
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="farmer">
                                                <option value="">-Select Customer-</option>
                                            </select>

                                        </div>
                                    </div>
                                }
                                {(partyType === "Customer" || partyType === "Contractor" || partyType === "Linesight") &&
                                    <div className='col-md-2 mb-0'>
                                        <div className="form-group">
                                            <select class="form-control form-control-sm" name="uom">
                                                <option value="">-Select Production Plant-</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className="col-md-2 mb-0">
                                    <Datepicker
                                        placeholderText="Select From Date"
                                        maxDate={new Date()}
                                        //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                        dateFormat="dd-MMM-yyyy"
                                        name="closeDate"
                                        className="form-control form-control-sm"
                                    >
                                    </Datepicker>
                                </div>
                                <div className="col-md-2 mb-0">
                                    <Datepicker
                                        placeholderText="Select To Date"
                                        maxDate={new Date()}
                                        //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                        dateFormat="dd-MMM-yyyy"
                                        name="closeDate"
                                        className="form-control form-control-sm"
                                    >
                                    </Datepicker>
                                </div>
                                <div className="col mb-0">
                                    <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div class="card-body">

                    <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={data}
                                defaultSortField="batchID"
                                pagination
                                //selectableRows
                                // selectableRowsComponent={BootyCheckbox}
                                // onSelectedRowsChange = {handleChange}
                                dense
                                selectableRowsHighlight='true'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default Inventory;