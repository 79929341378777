import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { productionRisksCalendar, getProductionScheduleLinkedToWorkId, supplierRecomendationForMaterial, getProductionSchedularRisk } from '../pages/const';

const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
        backgroundColor: isSelected ? '#3174ad' : '#f1c1c1', // Highlight color for selected event, default color otherwise
        borderRadius: '0px',
        opacity: isSelected ? 1 : 0.8, // Adjust opacity for selected event
        color: isSelected ? 'white' : 'black', // Set text color for better contrast
        border: 'none',
        height: '100%'
    };
    return {
        style,
    };
};

const ProductionRiskWorkSchedules = () => {
    const [offcanvas, setoffcanvas] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [workEvents, setWorkEvents] = useState([])
    const [selectedWorkEvent, setSelectedWorkEvent] = useState({})
    const [poList, setPoList] = useState([])
    const [clickedIndex, setClickedIndex] = useState(0);
    const [rowData, setRowData] = useState([])
    const [risks, setRisks] = useState([])
    const [riskData, setRiskData] = useState()
    const history = useHistory();
    const [dataWiseMonthsDropdown, setDataWiseMonthsDropdown] = useState([])
    const [currentDate, setCurrentDate] = useState(new Date());
    const [siteId, setSiteId] = useState(sessionStorage.getItem("siteId"));
    const [siteName, setSiteName] = useState(sessionStorage.getItem("siteName"));
    const [materialNameSelectedToViewRisks, setMaterialNameSelectedToViewRisks] = useState();


    const columns = [
        {
            name: 'Material Name',
            selector: row => row.materialName,
            minWidth: "250px"
        },
        {
            name: 'QTY',
            selector: row => row.quantity,
        },
        {
            name: 'UOM',
            selector: row => row.uom,
        },
        {
            name: 'NEED BY DATE',
            selector: row => moment(row.needByDate).format('DD-MMM-YYYY'),
        },
        {
            name: 'PO NUMBER',
            selector: row => row.orderNumber,
            minWidth: "200px"
        },
        {
            name: 'SUPPLIER',
            selector: row => row.supplierId,
        },
        {
            name: 'DEVIATION DAYS',
            selector: row => row.deviationDays,
            cell: row => (
                <>
                    {row.deviationDays}
                    <i
                        className="fa fa-info-circle"
                        style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                        onClick={() => riskMitigation(row)} // Make sure to use an arrow function here
                    ></i>
                </>
            ),
        }

    ];
    const riskMitigation = (data) => {
        setMaterialNameSelectedToViewRisks(data.materialName)
        supplierRecomendationForMaterial(data).then(res => {
            if (res.status === 200) {
                setRisks(res.data.data)
                // setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
        let riskPayLoad = {
            deviationDays: data.deviationDays,
            needByDate: data.needByDate
        }
        getProductionSchedularRisk(riskPayLoad).then(res => {
            if (res.status === 200) {
                setRiskData(res.data.data)
                // setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });

    }

    const handleEventClick = event => {
        setoffcanvas(true)
        setSelectedWorkEvent(event)
        getProductionScheduleLinkedToWorkId(event.title).then(res => {
            if (res.status === 200) {
                setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    };

    const closeSideOverlay = () => {
        setoffcanvas(false);
        setPopoverVisible(false);
        setRisks([])
    };

    const handleBackClick = () => {
        history.push('/dashboard', { propActiveState: 'productionRisks' });
    };
    useEffect(() => {
        // setShowLoader(true)
        productionRisksCalendar({ siteId: siteId, type: 'Risks' }).then(res => {
            if (res.status === 200) {
                setWorkEvents(res.data.data)
                // setShowLoader(false)
                setDataWiseMonthsDropdown(res.data.months)
                setCurrentDate(new Date(res.data.months[0].year, res.data.months[0].month - 1, 1));
            }
        }).catch((err) => {
            console.log(err)
        });

    }, []);

    const handleMonthChange = (event) => {
        const { value } = event.target;
        const selectedDateObject = JSON.parse(value);
        const { month, year } = selectedDateObject;
        const newDate = new Date(year, month - 1, 1); //
        setCurrentDate(newDate);
    };

    return (
        <div id="claenderView">
            <div class="row mb-2">
                <div class="col-sm-5">
                    {/* <h2 class="m-0 text-dark">Production Shedules at risk, &nbsp; &nbsp;
                        <span style={{ fontSize: 13 }}>
                            <strong>Production Plant: </strong> <span className='textHighlighter'>{siteName}</span>
                        </span>
                    </h2> */}
                </div>
                <div class="col-sm-3" >
                    <div class="input-group">{
                        dataWiseMonthsDropdown.length == 0 ?
                            <div><span className='redcolor' style={{ fontSize: 16, marginTop: 14 }}>No Risks Reported</span></div>
                            :
                            <select class="form-control form-control-sm" name="farmer" onChange={handleMonthChange} >
                                {dataWiseMonthsDropdown.length !== 0 &&
                                    dataWiseMonthsDropdown.map((item, index) => (
                                        <option key={index} value={JSON.stringify(item)}>
                                            {item.monthsMap}
                                        </option>
                                    ))
                                }
                            </select>
                    }

                    </div>
                </div>
                <div className='col-1'></div>
                <div class="col-sm-3" >
                    {/* <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span> */}
                </div>
            </div>
            <div className=''>
                {/* <div className='card-body'> */}
                <Calendar
                    localizer={localizer}
                    events={workEvents}
                    style={{ margin: '', height: '70vh', fontSize: "14px" }}
                    eventPropGetter={eventStyleGetter}
                    views={['month']}
                    onSelectEvent={handleEventClick}
                    date={currentDate}
                    onNavigate={(date) => setCurrentDate(date)}
                    selectable
                    onSelectSlot={(slotInfo) => console.log('Selected slot:', slotInfo)}
                    eventOverlapAccessor={() => true} // Allows events to overlap
                    eventOffsetAccessor={10} // Sets the vertical offset between overlapping events
                />
                {/* </div> */}
            </div>
            <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "75%" }}>
                <div className="overlay-content">
                    <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                    {/* <button className="close-button" >Close</button> */}
                    <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                        <div className='col-md-12'>
                            <div className='card-body'>
                                <div className='title row' style={{ justifyContent: "left" }}>
                                    <h2 className='p-1 ml-1 m-2 text-dark'>
                                        PURCHASE ORDERS LINKED TO PRODUCTION
                                    </h2>
                                </div>
                                <div className='row mt-4 sideOverlaySummary'>
                                    <div className='col-4'>
                                        <strong>Production ID: </strong>
                                        {poList.length > 0 && poList[0].workId}
                                    </div>
                                    <div className='col-3'>
                                        <strong>Production Start Date:</strong> {poList.length > 0 && moment(poList[0].prodActualStartDate).subtract(1, 'day').format('DD-MMM-YYYY')}
                                    </div>
                                    <div className='col-3'>
                                        <strong>Production End Date:</strong> {poList.length > 0 && moment(poList[0].prodActualEndDate).subtract(1, 'day').format('DD-MMM-YYYY')}
                                    </div>
                                </div>
                                <div className='row mt-4' style={{ border: '1px solid black' }}>

                                    <div className='col-md-12'>

                                        <div className='row mt-3'>
                                            <div className='col-md-12'>
                                                <DataTable
                                                    columns={columns}
                                                    data={poList}
                                                    pagination
                                                    //selectableRows
                                                    // selectableRowsComponent={BootyCheckbox}
                                                    // onSelectedRowsChange = {handleChange}
                                                    dense
                                                    selectableRowsHighlight='true'
                                                    compact
                                                    highlightOnHover='true'
                                                    striped
                                                />
                                            </div>
                                        </div>
                                        {risks.length > 0 &&
                                            <>
                                                <div className='row mt-4 pl-3'>
                                                    <h2>
                                                        <strong>Materail Name : </strong> {materialNameSelectedToViewRisks}
                                                    </h2>
                                                </div>
                                                <div className='row mt-4' style={{ fontSize: "15px" }}>
                                                    <div className='col-6'>
                                                        <div className='title row' style={{ justifyContent: "center" }}>
                                                            <h2 class="m-2 text-dark">
                                                                Risk
                                                            </h2>
                                                        </div>

                                                        {/* <div className="order-details">
                                                        <div className="detail-row">
                                                            <div className="detail-key">Order Confirmation Date</div>
                                                            <div className="detail-value">26/11/2023</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Lead Time</div>
                                                            <div className="detail-value">210 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Available Time - Installation</div>
                                                            <div className="detail-value">170 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Estimated Shipment Delay</div>
                                                            <div className="detail-value">40 days</div>
                                                        </div>
                                                    </div> */}

                                                        <tabel>
                                                            <tbody className='table table-striped' id='sideOverlaytable'>
                                                                <tr>
                                                                    <td className='header'>Production delay <small>(in Days)</small> </td>
                                                                    <td>{riskData && riskData.productionDelay}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='header'>Need by date </td>
                                                                    <td>{riskData && moment(riskData.needByDate).format('DD-MMM-YYYY')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='header'>Logistics delay <small>(in Days)</small></td>
                                                                    <td>{riskData && riskData.logisticsDelay}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='header'>ETA delay <small>(in Days)</small></td>
                                                                    <td>{riskData && riskData.etaDelay}</td>
                                                                </tr>

                                                            </tbody>
                                                        </tabel>
                                                    </div>
                                                    <div className='col-6' style={{ borderLeft: "1px solid #666" }}>
                                                        <div className='title row' style={{ justifyContent: "center" }}>
                                                            <h2 class="m-2 text-dark">
                                                                RISK MITIGATION
                                                            </h2>
                                                        </div>
                                                        <p style={{ marginTop: 10 }}>
                                                            Recommended suppliers / Production Plant to get on-time
                                                            delivery or delay less than current ETA
                                                        </p>
                                                        <ol className='ml-0' st9yle={{ lineHeight: 2, fontSize: 13 }} >
                                                            {risks.map((item) => (
                                                                <li><strong>{item.supplierName}</strong> - OnHand ({item.inventory != 0 ? item.inventory + ' EA' : item.inventory}) - {item.recommendedSupplier.deviationDaysToNeedByDate === 0 ? 'On Time' : 'Delay'} ({moment(item.recommendedSupplier.expectedDeliveryDate).format('DD-MMM-YYYY')})</li>
                                                            ))}
                                                        </ol>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProductionRiskWorkSchedules