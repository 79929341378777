import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env } from './const';
import DataTable from "react-data-table-component";
// import { headers } from '../utils/common';
// import { Role } from '../utils/role';
// import { getRole } from '../utils/common';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import InwardList from './InwardList';
import GRN from './GRN';

const Inward = (props) => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const [selectedDate, setSelectedDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const dateSelector = (date) => {
        setSelectedDate(date);
    }
    const fromdateSelector = (date) => {
        setFromDate(date);
    }
    const todateSelector = (date) => {
        setToDate(date);
    }
    const columns = [
        {
            name: "Supplier ID",
            selector:(row)=> row.farmName,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Supplier Name",
            selector:(row)=> row.createdDate,
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "Unique ID",
            selector:(row)=> row.numberofbatches,
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "GRN #",
            selector:(row)=> row.numberofbatches,
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "GRN Date",
            selector:(row)=> row.numberofbatches,
            sortable: true,
            minWidth: '100px'
        },
        {
            name: "Delivery Truck #",
            selector:(row)=> row.numberofbatches,
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "Driver Name",
            selector:(row)=> row.numberofbatches,
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "QR Code",
            selector:(row)=> row.numberofbatches,
            sortable: true,
            minWidth: '80px'
        },
    ];
    const [data, setData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [materialData, setMaterialData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const onSubmit = (data, e) => {

        e.preventDefault();
        //alert(JSON.stringify(data));
        data.grnDate = moment(selectedDate).format('YYYY-MM-DD');
        axios.post(env.produrl + '/entry/addinward', data)
            .then(res => {
                console.log(res.data.message);
                // alert(res.data.message)
                setShowResults(true);
                setResultMessage({
                    error: false, message: res.data.message
                });
                e.target.reset();
            }).catch((err) => {
                console.log(err)
                setShowResults(true)
                setResultMessage({
                    error: true, message: err.response.data.message
                });
                console.log(err.response.data.message);
            });
    }
    const _siteData = () => {
        axios.get(env.produrl + '/misc/sitemaster')
            .then(res => {

             
                setSiteData(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
    }
    const _materialsData = () => {
        axios.get(env.produrl + '/misc/materialmaster')
            .then(res => {

                // console.log(res.data.list);
                setMaterialData(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
    }
    const _supplierData = () => {
        axios.get(env.produrl + '/misc/suppliermaster')
            .then(res => {

                // console.log(res.data.list);
                setSupplierData(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
    }
    const [uom, setUom] = useState([])
    const uomList = () => {
        axios.get(env.produrl + '/misc/uomlist')
            .then(res => {

                setUom(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
    }
    useEffect(() => {
        // _siteData();
        // _materialsData();
        // _supplierData();
        // uomList();
    }, [])
    return (
        <div className="inwards">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Inward Goods</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Create QR - Inward">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-9'>
                                        {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Material</label>
                                            <select class="form-control form-control-sm" name="farmer"
                                                {...register("productID", {
                                                    required: "Please select material",
                                                    // onChange: (e) => { onFarmerChange(e) }
                                                })}
                                            >
                                                <option value="">-Select-</option>
                                                {materialData.map(item => (
                                                    <option
                                                        key={item.materialId}
                                                        value={item.materialId}
                                                    >
                                                        {item.description}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.productID && <span className="err-msg">{errors.productID.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Supplier</label>
                                            <select class="form-control form-control-sm" name="farmer"
                                                {...register("supplierID", {
                                                    required: "Please select Supplier",
                                                    // onChange: (e) => { onFarmerChange(e) }
                                                })}
                                            >
                                                <option value="">-Select-</option>
                                                {supplierData.map(item => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.supplierName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.supplierID && <span className="err-msg">{errors.supplierID.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor> Production Plant</label>
                                            <select class="form-control form-control-sm" name="farmer"
                                                {...register("siteID", {
                                                    required: "Please select Construction Production Plant",
                                                    // onChange: (e) => { onFarmerChange(e) }
                                                })}
                                            >
                                                <option value="">-Select-</option>
                                                {siteData.map(item => (
                                                    <option
                                                        key={item.id}
                                                        value={item.siteID}
                                                    >
                                                        {item.siteName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.siteID && <span className="err-msg">{errors.siteID.message}</span>}
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor>Quantity</label>
                                                    <input type="number" className="form-control form-control-sm" placeholder="Quantity"
                                                        name="quantity"
                                                        {...register("quantity", {
                                                            required: "Quantity",

                                                        })}
                                                    />
                                                    {errors.quantity && <span className="err-msg">{errors.quantity.message}</span>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label htmlFor>UOM</label>
                                                    <select class="form-control form-control-sm" name="uom"
                                                        {...register("uom", {
                                                            required: "Please select UOM",
                                                            // onChange: (e) => { onFarmerChange(e) }
                                                        })}
                                                    >
                                                        <option value="">-Select-</option>
                                                        {uom.map(item => (
                                                            <option
                                                                key={item.uomValue}
                                                                value={item.uomValue}
                                                            >
                                                                {item.uomValue}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.uom && <span className="err-msg">{errors.uom.message}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>UOM Conversion Ratio</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Ratio"
                                                name="boxeachratio"
                                                {...register("boxeachratio", {
                                                    required: "Please enter Box to Each Ratio",

                                                })}
                                            />
                                            {errors.boxeachratio && <span className="err-msg">{errors.boxeachratio.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Unique Identifier</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Identifier"
                                                name="uid"
                                                {...register("uid", {
                                                    required: "Please enter Unique Identifier",

                                                })}
                                            />
                                            {errors.uid && <span className="err-msg">{errors.uid.message}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>PO Number</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter PO #"
                                                name="ponumber"
                                                {...register("ponumber", {
                                                    required: "Please enter PO Number",

                                                })}
                                            />
                                            {errors.ponumber && <span className="err-msg">{errors.ponumber.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>GRN</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter GRN"
                                                name="grn"
                                                {...register("grn", {
                                                    required: "Please enter GRN",

                                                })}
                                            />
                                            {errors.grn && <span className="err-msg">{errors.grn.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>GRN Date</label>
                                            <Datepicker
                                                selected={selectedDate}
                                                onChange={dateSelector}
                                                //showTimeSelect
                                                //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                                placeholderText="Select GRN Date"
                                                maxDate={new Date()}
                                                //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                                dateFormat="dd-MMM-yyyy"
                                                name="closeDate"
                                                className="form-control form-control-sm"
                                            >
                                            </Datepicker>

                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Truck Number</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Trcuk Number"
                                                name="truckNumber"
                                                {...register("truckNumber", {
                                                    required: "Please enter Truck Number",

                                                })}
                                            />
                                            {errors.truckNumber && <span className="err-msg">{errors.truckNumber.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Driver Name</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Driver Name"
                                                name="driverName"
                                                {...register("driverName", {
                                                    required: "Please enter driver name",

                                                })}
                                            />
                                            {errors.driverName && <span className="err-msg">{errors.driverName.message}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor>Mobile Number</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Ratio"
                                                name="mobileNumber"
                                                {...register("mobileNumber", {
                                                    required: "Please enter mobile number",

                                                })}
                                            />
                                            {errors.mobileNumber && <span className="err-msg">{errors.mobileNumber.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input type="submit" value="Submit" class="btn btn-primary newBtn" />
                                        <input type="reset" value="Reset" class="btn btn-secondary newBtn" />
                                    </div>
                                </div>
                            </form>
                        </Tab>
                        <Tab label="GRN">
                            <GRN></GRN>
                        </Tab>
                        <Tab label="Inward Goods">
                            <InwardList></InwardList>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}
                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default Inward;