const FinishgoodsData = {
  "name": "Batch: KURKURE-001",
  "children": [
    {
      "name": "Processing Plant: Frito-Lay Plant, Texas",
      "children": [
        {
          "name": "Region: California",
          "children": [
            {
              "name": "Super Stockist: UNFI, Los Angeles",
              "children": [
                {
                  "name": "Sub-Distributor: McLane Company, San Francisco",
                  "children": [
                    { "name": "Retail Outlet: Walmart, San Francisco" },
                    { "name": "Retail Outlet: Target, San Francisco" }
                  ]
                },
                {
                  "name": "Sub-Distributor: Core-Mark, Orange County",
                  "children": [
                    { "name": "Retail Outlet: Kroger, Orange County" },
                    { "name": "Retail Outlet: Safeway, Orange County" }
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "Region: Texas",
          "children": [
            {
              "name": "Super Stockist: KeHE, Dallas",
              "children": [
                {
                  "name": "Sub-Distributor: McLane Company, Austin",
                  "children": [
                    { "name": "Retail Outlet: H-E-B, Austin" },
                    { "name": "Retail Outlet: Whole Foods, Austin" }
                  ]
                },
                {
                  "name": "Sub-Distributor: Core-Mark, Houston",
                  "children": [
                    { "name": "Retail Outlet: Walmart, Houston" },
                    { "name": "Retail Outlet: Costco, Houston" }
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "Region: New York",
          "children": [
            {
              "name": "Super Stockist: KeHE, New York",
              "children": [
                {
                  "name": "Sub-Distributor: McLane Company, Long Island",
                  "children": [
                    { "name": "Retail Outlet: ShopRite, Long Island" },
                    { "name": "Retail Outlet: Stop & Shop, Long Island" }
                  ]
                },
                {
                  "name": "Sub-Distributor: Core-Mark, New Jersey",
                  "children": [
                    { "name": "Retail Outlet: Walmart, New Jersey" },
                    { "name": "Retail Outlet: Target, New Jersey" }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}


  
  export default FinishgoodsData;
  