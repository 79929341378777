import React, { useEffect, useState } from 'react';
import { uploads } from '../pages/const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import Swal from 'sweetalert2';


const ExcelUploader = ({ uploadType, filePath, responseDataSend, tabKey }) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="white" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    // const [excelData, setExcelData] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setFileUploaded(true);
    };

    const preview = () => {
        setShowLoader(true)
        const formData = new FormData();
        formData.append('file', file);
        uploads(formData, uploadType).then(res => {
            if (res.status === 200) {
                responseDataSend(res.data);
                setShowLoader(false)
            }
            else {
                Swal.fire({
                    title: "Oops...",
                    text: res.data.status.code,
                    icon: "error"
                })
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    useEffect(() => {
        setFile(null);
    }, [tabKey]);
    return (

        <div className="excel-uploader mt-4">
            <div>
                <a href={process.env.PUBLIC_URL + filePath} download style={{ color: 'blue', cursor: 'pointer', marginLeft: '7px' }}>
                    <i class="fa fa-download" aria-hidden="true"></i> Download Sample Template
                </a>
            </div>
            <div className="excel-uploader mb-5" style={{ marginTop: '8px' }}>
                <input type="file" accept=".xlsx,.xls,.xlxs" onChange={handleFileChange} style={{ border: "1px solid black", borderRadius: "4px", padding: "5px" }} />
                <button style={{ marginTop: "-4px", marginLeft: "6%" }} className="btn btn-sm promary-btn loggedasButton"
                    disabled={!fileUploaded || showLoader} onClick={() => preview()}>
                    {showLoader ? (
                        <section {...containerProps} style={{ marginTop: "0px", display: 'inline-block', verticalAlign: 'middle' }}>
                            {indicatorEl}
                        </section>
                    ) : (
                        "Preview"
                    )}

                </button>
            </div>
            {
                // showLoader ?
                //     <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                //         {indicatorEl} {/* renders only while loading */}
                //     </section> : ""
            }

            {/*  {excelData && (
                <div className="excel-preview">
                    //Render the Excel preview here
                    <pre>{excelData}</pre>
                </div>
          )}*/}
        </div >
    );
};

export default ExcelUploader;

