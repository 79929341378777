const trackingInfo = {
  name: "Batch: KURKURE-001",
  children: [
    {
      name: "Transport: SOYA-001",
      info:{
        products:["Frito Lay","	Cheetos Flamin' Hot Twisted flavour","Quaker Oats"],
        temparature:["5","8","No Deviation","no"]

      },
      children: [
        {
          name: "Storage: North Cold Storage Warehouse ",
          
          children: [
            {
              name: "Processing: Flaking ",
              children: [
                {
                  name: "Supplier: Beans Suppliers",
                  children: [
                    {
                      name: "Farm: Soya Bean Plantation - Iowa Soya Growers",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: SOYA-002",
      info:{
        products:["Frito Lay","	Cheetos Flamin' Hot Twisted flavour"],
        temparature:["5","8","No Deviation","no"]

      },
      children: [
        {
          name: "Storage:Central Cold Storage Warehouse ",
          children: [
            {
              name: "Processing: Flaking and Extraction",
              children: [
                {
                  name: "Supplier: Northern Grain Partners",
                  children: [
                    {
                      name: "Farm: Soya Farm - Ohio Soya Producers",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: COCOA-002",
      info:{
        products:["Frappucino Coffee","Quaker Rice Thins Cocoa Crunch","Quaker choclate chip cookies"],
        temparature:["18","20","No Deviation","no"]
      },
      children: [
        {
          name: "Storage: Dry Storage Warehouse",
          children: [
            {
              name: "Processing: Cleaning & Grinding Cocoa",
              children: [
                {
                  name: "Supplier: Cocoa Beans Suppliers",
                  children: [
                    {
                      name: "Farm: Cocoa Plantation - California Cocoa Fields",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: RUBBER-003",
      info:{
        products:["Pepsi Rubber Ball Bottle"],
        temparature:["15","25","No Deviation","no"]
      },
      children: [
        {
          name: "Storage:North Temperature Controlled Warehouse ",
          children: [
            {
              name: "Processing: Extrusion and Molding",
              children: [
                {
                  name: "Supplier: Rubber Suppliers",
                  children: [
                    {
                      name: "Farm: Rubber Plantation - Washington Rubber Co.",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: DAIRY-004",
      info:{
        products:["Quaker yogurt granola bars","Quaker Milk choclate Oatmeal Cookies","Nacho Cheese Doritos"],
        temparature:["0","22","No Deviation","no"]
      },
      children: [
        {
          name: "Storage: West Cold Storage Warehouse ",
          children: [
            {
              name: "Processing: Milk Pasteurizing & Processing",
              children: [
                {
                  name: "Supplier: Dairy Suppliers",
                  children: [
                    {
                      name: "Farm: Dairy Farm - Texas Dairy Farm",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: WOOD-005",
      info:{
        products:["Packaging","Paper Bottle",],
        temparature:["10","25","Deviated to 26 C For 1 Min","yes"]
      },
      children: [
        {
          name: "Storage: Wood Warehouse",
          children: [
            {
              name: "Processing: Shaping and Sanding",
              children: [
                {
                  name: "Supplier: Wood Suppliers",
                  children: [
                    {
                      name: "Farm: Wood Plantation - Florida Gold Roots",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    { 
      name: "Transport: PALM-OIL-007",
       
      info:{
        products:["Lays","Doritos","Cheetos"],
        temparature:["10","15","Deviated to 17 C For 3 Min","yes"]
      },
      children: [
        {
          name: "Storage: Bulk Liquid Storage Facility",
          children: [
            {
              name: "Processing: Pressing & Refining",
              children: [
                {
                  name: "Supplier: Palm Bunch Suppliers",
                  children: [
                    {
                      name: "Farm: Palm Plantation - Florida Sunflower Fields",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default trackingInfo;
