import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
const SkillView = ({ showPopup, taskID, closePopup, skillData }) => {
    const taskcolumns = [
        {
            name: "Skill",
            selector: "requiredSkill",
            sortable: true,
            width: '200px'
        },
        {
            name: "Man Power",
            selector: "manPowerRequired",
            sortable: true,
        },

    ];
    const [taskData, setTaskData] = useState(skillData);
    return (
        <Modal show={showPopup} onHide={closePopup} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Batch ID - {taskID}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <DataTable
                            columns={taskcolumns}
                            data={taskData}
                            defaultSortField="taskID"
                            pagination
                            dense
                            selectableRowsHighlight='true'
                            compact
                            highlightOnHover='true'
                            striped
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closePopup}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SkillView;
