import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
const MaterialInfo = ({ showPopup, taskID, closePopup, materialData }) => {
    const columns = [
        {
            name: "ID",
            selector: "materialId",
            sortable: true,
        },
        {
            name: "Category",
            selector: "materialCategory",
            sortable: true,
            maxWidth: '200px'
        },
        {
            name: "Name",
            selector: "materialName",
            sortable: true,
            maxWidth: '200px'
        },
        {
            name: "Description",
            selector: "materialDescription",
            sortable: true,
            maxWidth: '200px'

        },
        {
            name: "Qty",
            selector: "quantity",
            sortable: true,
        },
        {
            name: "UOM",
            selector: "uom",
            sortable: true,
        },

    ];
    const [data, setData] = useState(materialData);
    return (
        <Modal show={showPopup} onHide={closePopup} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Batch ID - {taskID}, Material Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <DataTable
                            columns={columns}
                            data={data}
                            defaultSortField="materialID"
                            pagination
                            dense
                            selectableRowsHighlight='true'
                            compact
                            highlightOnHover='true'
                            striped
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closePopup}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MaterialInfo;
