import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { IoEyeOutline } from "react-icons/io5";
const LogisticsRisks = (props) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    })
    const history = useHistory();
    const [showLoader, setShowLoader] = useState(false);
    const changeHandler = (event) => {
        //Update Graph Parameters
    }
    const sites = {
        data: [
            {
                name: 'New York',
                workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 5, 3, 2],
                materialsAtRisk: [1, 2, 1, 3, 2, 2, 1, 2, 2, 1, 2, 2]
            },
            {
                name: 'San Francisco',
                workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 3, 1, 4],
                materialsAtRisk: [1, 2, 1, 3, 2, 2, 1, 2, 2, 1, 2, 2]
            },
            {
                name: 'Seattle',
                workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 5, 3, 2, 3],
                materialsAtRisk: [1, 2, 1, 3, 2, 2, 1, 2, 2, 1, 2, 2, 1]
            },
            {
                name: 'Dallas',
                workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 5, 3, 2],
                materialsAtRisk: [1, 2, 1, 3, 2, 2, 1, 2, 2, 1, 2, 2, 1]
            }
        ],
        labels: ['<2Days', '>2Days - <1Week', '>1Week-15Days', '>1Week-<15Days', '>15Days-1Month']

    }


    let options = {

        tooltip: {
            trigger: 'item',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (item) {
                // Finding the site's additional data based on the seriesName of the hovered item
                const siteData = sites.data.find(site => site.name === item.seriesName);
                if (!siteData) return ''; // In case no data is found for the site, return an empty string

                // Accessing the specific data for the hovered site
                const workSchedulesAtRisk = siteData.workSchedulesAtRisk[item.dataIndex];
                const materialsAtRisk = siteData.materialsAtRisk[item.dataIndex];

                // Constructing the tooltip content
                let tooltipContent = `<div><strong>${item.marker} ${item.seriesName} - ${item.name}</strong></div>
                                      <div>Production Schedules at risk: <strong>${workSchedulesAtRisk}</strong></div>
                                      <div>Materials at risk: <strong>${materialsAtRisk}</strong></div>`;


                return tooltipContent;
            }
        },
        legend: {
            data: sites.data.map(item => item.name),
            orient: 'horizontal', // This is optional as 'horizontal' is the default value
            top: '0%',
            right: '0%'
        },
        grid: {

            left: 40,
            top: 30,
            right: 35,
            bottom: 30,
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: sites.labels,
                axisTick: {
                    alignWithLabel: true
                },
                name: 'Time Range',
                nameLocation: 'middle',
                nameGap: 30,
                axisLabel: {
                    interval: 0, // Display every label
                    //rotate: 45, // Optional: Rotate labels to prevent overlapping
                    fontSize: 11,
                    color: '#555',

                    formatter: function (value) {
                        // Optional: Format label, if you want to show labels differently
                        return value;
                    }
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Production Schedules at Production Plant',
                nameLocation: 'middle',
                nameGap: 50
            }
        ],
        series: sites.data.map(site => ({
            name: site.name,
            type: 'bar',
            barWidth: '15%',
            stack: 'total',
            label: {
                show: false
            },
            emphasis: {
                focus: 'series'
            },
            data: site.workSchedulesAtRisk
        })),

    };
    const LogisticsRisksInfo = () => {
        // Navigate to the desired screen upon click
        history.push('/ShipmentsDeviations');
    };
    return (
        <div className="Temp">
            {/* {props.data.xAxis.length>0 ? */}
            <div className='row' style={{ marginBottom: 15 }}>
                <div className='col-md-6'>
                    <div class="form-inline">
                        <h2 class="m-0 text-dark" style={{ display: 'inline' }}>Production Plant Wise Deviations &nbsp; &nbsp;
                            {showLoader ?
                                <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                                    {indicatorEl} {/* renders only while loading */}
                                </section> : ""
                            }
                        </h2>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div class="row justify-content-end">

                        <div class="col-auto">

                            <div class="form-inline">
                                <label for="exampleSelect" class="mr-2">Filter:</label>
                                <select onChange={changeHandler} class="form-control form-control-sm" id="exampleSelect">
                                    <option value={12}>Next 12 Months (default)</option>
                                    <option value={6}>Next 6 Months</option>
                                    <option value={18}>Next 18 Months</option>
                                </select>&nbsp;&nbsp;&nbsp;
                                <IoEyeOutline title="View Details" style={{ float: "right", color: "black", fontSize: "20px", cursor: "pointer" }} onClick={LogisticsRisksInfo} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ReactEcharts
                option={options}
                style={{ height: "300px", width: "100%" }}
                opts={{ renderer: "svg" }}
            />
        </div>
    );
}
export default LogisticsRisks;